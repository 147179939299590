.home-content {
    max-width: 70rem;
    margin: 0 auto;
    padding: 0 3rem;
}

.home-background {
    background-color: var(--primary-color);
    width: 100%;
    height: 42rem;
    position: absolute;
    top: 0;
    z-index: -1;
    background-image: linear-gradient(#4840bb, #774ccd);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10vw));
}

.home-content--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.home-header {
    text-align: left;
    margin-bottom: 12rem;
}

.home-sub-header {
    margin: 0 auto 0.5rem 0;
    font-size: 1.5rem;
}

.home-header h1 {
    font-size: 4rem;
}

.home-header p {
    width: 30rem;
}

.main-logo {
    width: 25rem;
    margin-bottom: 2rem;
}

.home-description-wrapper {
    margin-top: 5rem;
}

.home-description {
    color: var(--secondary-text-color);
    font-weight: normal;
    font-size: 1rem;
    max-width: 45rem;
    text-align: left;
}

.home-strong-line {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 2rem;
}

.how-it-works-wrapper {
    max-width: 60rem;
    margin: 0 auto;
}

.how-it-works {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    color: var(--secondary-text-color);
}

.how-it-works img {
    width: 20rem;
    padding: 0 1rem;
    height: auto;
}

.how-it-works h3 {
    color: var(--primary-color);
    padding: 0;
}

.how-it-works-description {
    max-width: 25rem;
}

@media (max-width: 730px) {
    .how-it-works {
        margin: 5rem 0;
        flex-direction: column;
    }

    .how-it-works-2 {
        flex-direction: column-reverse;
    }
}

@media (max-width: 940px) {
    .home-how-it-works {
        text-align: center;
    }

    .home-background {
        height: 30rem;
    }

    .home-content--wrapper {
        flex-direction: column;
    }

    .home-header p {
        width: 20rem;
    }

    .home-sub-header {
        margin: 0px auto 2rem auto;
    }
    
    .main-logo {
        width: 15rem;
        margin-bottom: 2rem;
    }

    .home-header {
        text-align: center;
        margin: 0 auto;
        margin-top: 1rem;
        max-width: 32rem;
        padding: 0 0.5rem;
    }
    
    .home-header h1 {
        font-size: 2rem;
    }
}