#feedback-field textarea {
    font-family: inherit;
    width: 100%;
    height: 5rem;
    padding: 0.5rem 0.5rem;
    resize: none;
}

.rating-button {
    cursor: pointer;
    background: none;
    border: none;
}

.rating__input-star {
    margin: 0.5rem 0;
}