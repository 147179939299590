.Order {
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 0.75rem;
    width: 16rem;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
}

.item {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem auto;
}