.Card {
    background-color: var(--primary-background-color);
    border-radius: 1.25rem;
    padding: 1rem 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.Card h2 {
    margin: 0.3rem 0;
}

.Card h3 {
    font-size: 1.3rem;
}