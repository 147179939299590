.DoneeThumbnail {
    font-size: 0.8rem;
}

.donee-img {
    object-fit: cover;
    border-radius: 1.25rem;
    width: 20rem;
    height: 21rem;
}

.donee-thumbnail {
    width: 5rem;
}

.donee-thumbnail__desc {
    margin: 0.3rem 0;
    font-size: 0.9rem;
}

@media (max-width: 940px) {
    .DoneeThumbnail {
        margin-right: 0;
    }
}