.field {
    display: inline-block;
    width: 100%;
}

.input {
    background-color: var(--primary-background-color);
    color: var(--secondary-color);
    border: 1px solid #E2CFEA;
    border-radius: 5px;
    height: 2.5rem;
    font-size: 16px;
    padding: 0 1rem;
    margin: 0.5rem 0rem;
    width: calc(100% - 2rem);
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--secondary-color);
    box-shadow: 0 0 0px 1000px #102B3F inset;
    -webkit-box-shadow: 0 0 0px 1000px #102B3F inset;
}

.input::placeholder {
    color: var(--primary-placeholder-color);
    font-size: 1rem;
}

.field-error {
    color: var(--tertiary-color);
    display: block;
}