.ShareIconLink {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 0.75rem;
}

.ShareIconLink svg {
    height: 1rem;
    width: 1.5rem;
    margin-right: 0.5rem;
}