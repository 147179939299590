.donation-feed {
    padding: 0.1rem 0;
}

.donation {
    text-align: left;
    overflow: hidden;
    margin: 0.75rem 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-icon {
    margin-right: 0.5rem;
}

.donation-header {
    position: relative;
    margin: 0;
}

.donation-message {
    overflow: hidden;
    white-space: nowrap;
    margin: 0.1rem 0;
}

.donation-header {
    font-weight: bold;
    width: 100%;
}

.donation-message {
    font-size: 0.8rem;
    text-overflow: ellipsis;
}

.donation-info {
    display: inline-block;
    text-align: left;
    width: calc(100% - 3rem);
}
