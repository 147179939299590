.StripeElement {
    background-color: var(--primary-background-color);
    border-radius: 5px;
    padding: 13px 16px 3px 16px;
    margin: 0.5rem 0;
    height: calc(40px - 1rem);
    border: 1px solid #E2CFEA;
}

@media (max-width: 768px) {
    .StripeElement {
        padding: 9px 16px 6px 16px;
        height: 20px;
    }
}

#anonymous-donation-checkbox-field {
    padding: 0.5rem 0;
}

.payment-request-button-divisor {
    text-align: center;
    margin: 1rem auto;
}

.PaymentRequestButton {
    background-color: var(--primary-background-color);
    border: none;
    margin-top: 2rem;
}

.PaymentRequestButton > div {
    max-width: 20rem;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
}

.radio__input {
    visibility: hidden;
    position: absolute;
}

#donation-quick-select {
    margin: 0 0 0.5rem 0;
}

#donation-quick-select > .radio__label {
    cursor: pointer;
}

.radio__span:nth-child(2) {
    margin-left: 0.5rem;
}

.radio__span:last-child {
    margin-left: 0.5rem;
}

#radio__label-donation-quick-select {
    display: inline-block;
    background-color: var(--primary-background-color);
    color: var(--secondary-color);
    border: 1px #E2CFEA solid;
    border-radius: 0.2rem;
    width: calc(50% - 0.65rem);
    padding: 1rem 0 1rem 0;
    cursor: pointer;
    margin-top: 0.5rem;
}

.donation-quick-select-selected {
    background-color: #E2CFEA !important;
    color: #102B3F !important;
    border: none !important;
    font-weight: bold;
}

.donation-quick-select-selected > label {
    font-weight: bold;
}

#donation-message {
    resize: none;
    font-family: inherit;
    width: 18rem;
    height: 4rem;
    padding: 0.5rem 1rem;
}

.completed-donation-img {
    width: 15rem;
    border-radius: 1rem;
}