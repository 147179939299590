.nav {
    margin: 0.5rem 0rem;
    align-items: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0.5rem;
}

.nav-home-button {
    cursor: pointer;
    position: absolute;
    width: 9rem;
    height: 3.5rem;
    background: none;
    border: none;
    left: calc(50% - 4.5rem);
}

.MiniLogo {
    height: 2.5rem;
}

@media (max-width: 768px) {
    .MiniLogo {
        height: 2.5rem;
    }

    .back-button svg {
        height: 2.5rem;
    }
}

.back-button {
    padding: 0;
    background: none;
    border: none;
    position: absolute;
    left: 1rem;
    width: fit-content;
    top: 0.25rem;
    background-color: #FF585C;
    border-radius: 0.5rem;
}

.account-link {
    cursor: pointer;
    border: none;
    background: none;
    position: absolute;
    top: 0;
    right: 1rem;
    width: 3.3rem;
    color: var(--primary-background-color);
}

.nav-link {
    width: fit-content;
    position: absolute;
    font-weight: lighter;
    font-size: 1.2rem;
    top: 0.75rem;
}

.theme-toggle-button {
    background: none;
    border: none;
    height: 2.5rem;
    width: 3rem;
    padding: 0;
    position: relative;
    left: -5.5rem;
    float: right;
    cursor: pointer;
}

@media (max-width: 380px) {
    .theme-toggle-button {
        left: -4.8rem;
    }
}

.theme-toggle-button svg {
    height: 2.5rem;
    width: 3rem;
}

