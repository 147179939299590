html {
    min-height: 100%;
    font-size: 16px;
    overflow-x: hidden;
    --primary-color: #6247AA;
    --secondary-color: #E2CFEA;
    --primary-background-color: #102B3F;
    --primary-text-color: #FFFFFF;
    --secondary-text-color: #102B3F;
    --primary-placeholder-color: rgba(226, 207, 234, 0.7);
    --tertiary-color: #CF6679;
    --footer-background-color: #202124;
}

@media (max-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 320px) {
    html {
        font-size: 12px;
    }
}

body {
    margin: 0;
    background-color: #EEF2F7;
    color: var(--primary-text-color);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

h2, h3, h4, h5, h6 {
    font-weight: 600;
    padding: 0 0.5rem;
}

body, input, select, option {
    font-family: 'Baloo Chettan 2', cursive;
}

a, a:visited, a:focus {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: bold;
}
