.plan {
    width: 100%;
}

.plan-descriptions {
    display: flex;
}

.plan-step-description {
    font-size: 0.8rem;
    text-align: center;
    line-height: 1rem;
    margin-top: 0.3rem;
}

.plan-step-circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 100%;
    margin: 0 auto;
    background-color: var(--primary-color);
    z-index: 1;
    position: relative;
}

.plan-step-bridge {
    background-color: var(--primary-color);
    height: 0.2rem;
    position: relative;
    top: -0.45rem;
    left: 50%;
}