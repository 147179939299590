.campaign-progress {
    width: 15rem;
    margin: 0.5rem auto;
}

.campaign-progress-bar {
    display: block;
    width: 100%;
}

.campaign-progress-bar[value]::-webkit-progress-value {
    background-color: var(--primary-color);
    border-radius: 1rem;
}

.campaign-progress-bar[value]::-moz-progress-bar {
    background-color: var(--primary-color);
    border-radius: 1rem;
}

.campaign-progress-bar[value], .campaign-progress-bar[value]::-webkit-progress-bar {
    background-color: var(--secondary-color);
    border-radius: 1.1rem;
}

.campaign-progress-amount-to-go {
    margin-bottom: 0.3rem;
    display: block;
    font-weight: bold;
    font-size: 0.9rem;
}

.campaign-progress-amount-zero, .campaign-progress-goal, .campaign-progress-patrons {
    font-size: 0.75rem;
}

.campaign-progress-amount-zero {
    padding-top: 0.1rem;
    float: left;
}

.campaign-progress-goal {
    padding-top: 0.1rem;
    float: right;
    text-align: right;
}

.campaign-progress-patrons {
    margin-top: 1rem;
    display: block;
}