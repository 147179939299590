.account-links {
    display: contents;
    list-style: none;
}

.account-links li {
    margin: 1rem 0;
}

#account-link-log-out {
    cursor: pointer;
    color: var(--secondary-color);
}

.account-links li a, #account-link-log-out {
    font-weight: bold;
}