.account-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.account-profile {
    border-radius: 50%;
    margin-right: 1rem;
}

.AuthenticationLinks {
    right: 1rem;
    width: fit-content;
    position: absolute;
    top: 0.75rem;
}

.AuthenticationLinks a {
    color: var(--primary-text-color);
    font-size: 1.25rem;
    margin: 0 0.5rem;
    font-weight: lighter;
}

.offline-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    top: 0.75rem;
}

.offline-label > svg {
    height: 1.25rem;
}

.offline-label > span {
    font-size: 1.25rem;
    margin-left: 0.5rem;
}