.page {
	margin-top: 5rem;
	text-align: center;
	min-height: calc(100vh - 3rem);
}

.button {
	border-radius: 0.75rem;
    font-weight: bold;
    min-height: 3.5rem;
    display: block;
    min-width: 15rem;
    border: none;
    padding: 0 1rem;
	margin: 1rem auto;
	background-color: var(--secondary-color);
	color: #102B3F
}

.button:hover {
    cursor: pointer;
}

.button:disabled {
    cursor: not-allowed;
    color: var(--secondary-text-color);
}

@keyframes fadeInAndLower {
	from {
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		transform: translateY(10px);
	}
}

@keyframes fadeInAndRise {
	from {
		opacity: 0;
		visibility: hidden;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		transform: translateY(-10px);
	}
}

@keyframes fadeIn {
	from{
		opacity: 0;
		visibility: hidden;
	}
	to{
		opacity: 1;
		visibility: visible;
	}
}
