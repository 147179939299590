html {
    min-height: 100%;
    font-size: 16px;
    overflow-x: hidden;
    --primary-color: #6247AA;
    --secondary-color: #E2CFEA;
    --primary-background-color: #102B3F;
    --primary-text-color: #FFFFFF;
    --secondary-text-color: #102B3F;
    --primary-placeholder-color: rgba(226, 207, 234, 0.7);
    --tertiary-color: #CF6679;
    --footer-background-color: #202124;
}

@media (max-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 320px) {
    html {
        font-size: 12px;
    }
}

body {
    margin: 0;
    background-color: #EEF2F7;
    color: #FFFFFF;
    color: var(--primary-text-color);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

h2, h3, h4, h5, h6 {
    font-weight: 600;
    padding: 0 0.5rem;
}

body, input, select, option {
    font-family: 'Baloo Chettan 2', cursive;
}

a, a:visited, a:focus {
    text-decoration: none;
    color: #E2CFEA;
    color: var(--secondary-color);
    font-weight: bold;
}

.page {
	margin-top: 5rem;
	text-align: center;
	min-height: calc(100vh - 3rem);
}

.button {
	border-radius: 0.75rem;
    font-weight: bold;
    min-height: 3.5rem;
    display: block;
    min-width: 15rem;
    border: none;
    padding: 0 1rem;
	margin: 1rem auto;
	background-color: var(--secondary-color);
	color: #102B3F
}

.button:hover {
    cursor: pointer;
}

.button:disabled {
    cursor: not-allowed;
    color: var(--secondary-text-color);
}

@-webkit-keyframes fadeInAndLower {
	from {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translateY(-10px);
		        transform: translateY(-10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px);
	}
}

@keyframes fadeInAndLower {
	from {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translateY(-10px);
		        transform: translateY(-10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px);
	}
}

@-webkit-keyframes fadeInAndRise {
	from {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translateY(-10px);
		        transform: translateY(-10px);
	}
}

@keyframes fadeInAndRise {
	from {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translateY(-10px);
		        transform: translateY(-10px);
	}
}

@-webkit-keyframes fadeIn {
	from{
		opacity: 0;
		visibility: hidden;
	}
	to{
		opacity: 1;
		visibility: visible;
	}
}

@keyframes fadeIn {
	from{
		opacity: 0;
		visibility: hidden;
	}
	to{
		opacity: 1;
		visibility: visible;
	}
}

.nav {
    margin: 0.5rem 0rem;
    align-items: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0.5rem;
}

.nav-home-button {
    cursor: pointer;
    position: absolute;
    width: 9rem;
    height: 3.5rem;
    background: none;
    border: none;
    left: calc(50% - 4.5rem);
}

.MiniLogo {
    height: 2.5rem;
}

@media (max-width: 768px) {
    .MiniLogo {
        height: 2.5rem;
    }

    .back-button svg {
        height: 2.5rem;
    }
}

.back-button {
    padding: 0;
    background: none;
    border: none;
    position: absolute;
    left: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 0.25rem;
    background-color: #FF585C;
    border-radius: 0.5rem;
}

.account-link {
    cursor: pointer;
    border: none;
    background: none;
    position: absolute;
    top: 0;
    right: 1rem;
    width: 3.3rem;
    color: var(--primary-background-color);
}

.nav-link {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    font-weight: lighter;
    font-size: 1.2rem;
    top: 0.75rem;
}

.theme-toggle-button {
    background: none;
    border: none;
    height: 2.5rem;
    width: 3rem;
    padding: 0;
    position: relative;
    left: -5.5rem;
    float: right;
    cursor: pointer;
}

@media (max-width: 380px) {
    .theme-toggle-button {
        left: -4.8rem;
    }
}

.theme-toggle-button svg {
    height: 2.5rem;
    width: 3rem;
}


.account-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.account-profile {
    border-radius: 50%;
    margin-right: 1rem;
}

.AuthenticationLinks {
    right: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 0.75rem;
}

.AuthenticationLinks a {
    color: var(--primary-text-color);
    font-size: 1.25rem;
    margin: 0 0.5rem;
    font-weight: lighter;
}

.offline-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    top: 0.75rem;
}

.offline-label > svg {
    height: 1.25rem;
}

.offline-label > span {
    font-size: 1.25rem;
    margin-left: 0.5rem;
}
.donation-feed {
    padding: 0.1rem 0;
}

.donation {
    text-align: left;
    overflow: hidden;
    margin: 0.75rem 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-icon {
    margin-right: 0.5rem;
}

.donation-header {
    position: relative;
    margin: 0;
}

.donation-message {
    overflow: hidden;
    white-space: nowrap;
    margin: 0.1rem 0;
}

.donation-header {
    font-weight: bold;
    width: 100%;
}

.donation-message {
    font-size: 0.8rem;
    text-overflow: ellipsis;
}

.donation-info {
    display: inline-block;
    text-align: left;
    width: calc(100% - 3rem);
}

.Card {
    background-color: var(--primary-background-color);
    border-radius: 1.25rem;
    padding: 1rem 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.Card h2 {
    margin: 0.3rem 0;
}

.Card h3 {
    font-size: 1.3rem;
}
.button {
    border-radius: 0.5rem;
    font-weight: bold;
    min-height: 3.5rem;
    display: block;
    min-width: 15rem;
}
.campaign-progress {
    width: 15rem;
    margin: 0.5rem auto;
}

.campaign-progress-bar {
    display: block;
    width: 100%;
}

.campaign-progress-bar[value]::-webkit-progress-value {
    background-color: var(--primary-color);
    border-radius: 1rem;
}

.campaign-progress-bar[value]::-moz-progress-bar {
    background-color: var(--primary-color);
    border-radius: 1rem;
}

.campaign-progress-bar[value], .campaign-progress-bar[value]::-webkit-progress-bar {
    background-color: var(--secondary-color);
    border-radius: 1.1rem;
}

.campaign-progress-amount-to-go {
    margin-bottom: 0.3rem;
    display: block;
    font-weight: bold;
    font-size: 0.9rem;
}

.campaign-progress-amount-zero, .campaign-progress-goal, .campaign-progress-patrons {
    font-size: 0.75rem;
}

.campaign-progress-amount-zero {
    padding-top: 0.1rem;
    float: left;
}

.campaign-progress-goal {
    padding-top: 0.1rem;
    float: right;
    text-align: right;
}

.campaign-progress-patrons {
    margin-top: 1rem;
    display: block;
}
.DoneeThumbnail {
    font-size: 0.8rem;
}

.donee-img {
    object-fit: cover;
    border-radius: 1.25rem;
    width: 20rem;
    height: 21rem;
}

.donee-thumbnail {
    width: 5rem;
}

.donee-thumbnail__desc {
    margin: 0.3rem 0;
    font-size: 0.9rem;
}

@media (max-width: 940px) {
    .DoneeThumbnail {
        margin-right: 0;
    }
}
dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    max-width: calc(100vw - 6rem);
    padding: 1rem;
    background-color: var(--primary-background-color);
    color: var(--primary-text-color);
    border: none;
    border-radius: 5px;
    margin: 0;
}

#iOS-export-icon {
    width: 1rem;
    margin: 0 0.3rem;
}

dialog button {
    background: none;
    border: none;
    color: var(--primary-text-color);
    height: 2rem;
    width: auto;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--secondary-background-color);
    top: 1.5rem;
}

.dialog-footer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.dialog-primary-button {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);
}

.dialog-close-button {
    position: absolute;
    background-color: unset;
    width: 2.5rem;
    height: 2.5rem;
    font-weight: bold;
}

.dialog-close-button > svg {
    width: 1rem;
}

dialog::-webkit-backdrop { /* native */
    z-index: 3;
    background-color: rgba(0,0,0,0.4);
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
}

dialog::backdrop { /* native */
    z-index: 3;
    background-color: rgba(0,0,0,0.4);
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
}

dialog + .backdrop { /* polyfill */
    z-index: 3;
    background-color: rgba(0,0,0,0.4);
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
}
.ShareButton-header {
    padding: 0 3rem;
}

.ShareButton-link-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.ShareIconLink {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 0.75rem;
}

.ShareIconLink svg {
    height: 1rem;
    width: 1.5rem;
    margin-right: 0.5rem;
}
.home-content {
    max-width: 70rem;
    margin: 0 auto;
    padding: 0 3rem;
}

.home-background {
    background-color: var(--primary-color);
    width: 100%;
    height: 42rem;
    position: absolute;
    top: 0;
    z-index: -1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4840bb), to(#774ccd));
    background-image: linear-gradient(#4840bb, #774ccd);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10vw));
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10vw));
}

.home-content--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.home-header {
    text-align: left;
    margin-bottom: 12rem;
}

.home-sub-header {
    margin: 0 auto 0.5rem 0;
    font-size: 1.5rem;
}

.home-header h1 {
    font-size: 4rem;
}

.home-header p {
    width: 30rem;
}

.main-logo {
    width: 25rem;
    margin-bottom: 2rem;
}

.home-description-wrapper {
    margin-top: 5rem;
}

.home-description {
    color: var(--secondary-text-color);
    font-weight: normal;
    font-size: 1rem;
    max-width: 45rem;
    text-align: left;
}

.home-strong-line {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 2rem;
}

.how-it-works-wrapper {
    max-width: 60rem;
    margin: 0 auto;
}

.how-it-works {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    color: var(--secondary-text-color);
}

.how-it-works img {
    width: 20rem;
    padding: 0 1rem;
    height: auto;
}

.how-it-works h3 {
    color: var(--primary-color);
    padding: 0;
}

.how-it-works-description {
    max-width: 25rem;
}

@media (max-width: 730px) {
    .how-it-works {
        margin: 5rem 0;
        flex-direction: column;
    }

    .how-it-works-2 {
        flex-direction: column-reverse;
    }
}

@media (max-width: 940px) {
    .home-how-it-works {
        text-align: center;
    }

    .home-background {
        height: 30rem;
    }

    .home-content--wrapper {
        flex-direction: column;
    }

    .home-header p {
        width: 20rem;
    }

    .home-sub-header {
        margin: 0px auto 2rem auto;
    }
    
    .main-logo {
        width: 15rem;
        margin-bottom: 2rem;
    }

    .home-header {
        text-align: center;
        margin: 0 auto;
        margin-top: 1rem;
        max-width: 32rem;
        padding: 0 0.5rem;
    }
    
    .home-header h1 {
        font-size: 2rem;
    }
}
.form {
    max-width: 20rem;
    margin: 1rem auto;
    text-align: center;
}

.submission-error-message {
    max-width: 15rem;
    margin: 10px auto;
    background-color: var(--tertiary-color);
    color: var(--secondary-text-color);
    border-radius: 5px;
    padding: 5px 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.submission-error-message__text {
    margin: 0 0 0 10px;
}

.info-icon {
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
}

.form-submit {
    color: var(--secondary-text-color);
    font-size: 1rem;
}

.form-submit > span > .WhiteLoadingGIF {
    height: 1rem;
}
.field {
    display: inline-block;
    width: 100%;
}

.input {
    background-color: var(--primary-background-color);
    color: var(--secondary-color);
    border: 1px solid #E2CFEA;
    border-radius: 5px;
    height: 2.5rem;
    font-size: 16px;
    padding: 0 1rem;
    margin: 0.5rem 0rem;
    width: calc(100% - 2rem);
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--secondary-color);
    box-shadow: 0 0 0px 1000px #102B3F inset;
    -webkit-box-shadow: 0 0 0px 1000px #102B3F inset;
}

.input::-webkit-input-placeholder {
    color: var(--primary-placeholder-color);
    font-size: 1rem;
}

.input::-ms-input-placeholder {
    color: var(--primary-placeholder-color);
    font-size: 1rem;
}

.input::placeholder {
    color: var(--primary-placeholder-color);
    font-size: 1rem;
}

.field-error {
    color: var(--tertiary-color);
    display: block;
}
.login-links, .login-links span {
    margin: 10px auto;
}

.login-links span {
    display: block;
}

.Login > .form {
    max-width: 15rem;
}
.social-auth-button {
    cursor: pointer;
    height: 46px;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    margin: 2rem auto;
    border: none;
}

.social-auth-button span {
    margin: auto 0.6rem;
}

#google-button {
    width: 15.1rem;
    background-color: white;
    color: #757575;
}

#google-button:active {
    background-color: #EEEEEE;
}

#facebook-button {
    width: 17rem;
    background-color: #3C5A99;
    color: var(--primary-text-color);
}

#facebook-button svg {
    height: inherit;
}

@media (max-width: 768px) {
    .social-auth-button span {
        margin: auto 0.2rem;
    }

    #facebook-button span {
        margin-left: 0.8rem;
    }
}

@media (max-width: 320px) {
    #google-button span {
        margin: auto 0rem;
        width: 10rem;
    }
    
    #facebook-button span {
        margin-left: 0.7rem;
    }
}
.account-links {
    display: contents;
    list-style: none;
}

.account-links li {
    margin: 1rem 0;
}

#account-link-log-out {
    cursor: pointer;
    color: var(--secondary-color);
}

.account-links li a, #account-link-log-out {
    font-weight: bold;
}
.budget-item {
    display: flex;
    justify-content: space-between;
}

.budget-item-cost {
    text-align: right;
}

.budget-item-price, .budget-item-total-price, .budget-item-total {
    font-weight: bold;
}

.budget-item {
    margin: 0.3rem 0;
}

.budget-total {
    margin-top: 0.5rem;
}

.budget-item-total-price, .budget-item-total {
    font-size: 1.3rem;
}
.profile-info-story {
    text-align: left;
}

.profile-orgs-img {
    width: 12rem;
    border-radius: 1rem;
    float: left;
    margin: 1rem;
}

.profile-orgs-description {
    text-align: left;
}
.plan {
    width: 100%;
}

.plan-descriptions {
    display: flex;
}

.plan-step-description {
    font-size: 0.8rem;
    text-align: center;
    line-height: 1rem;
    margin-top: 0.3rem;
}

.plan-step-circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 100%;
    margin: 0 auto;
    background-color: var(--primary-color);
    z-index: 1;
    position: relative;
}

.plan-step-bridge {
    background-color: var(--primary-color);
    height: 0.2rem;
    position: relative;
    top: -0.45rem;
    left: 50%;
}
.Order {
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 0.75rem;
    width: 16rem;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
}

.item {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem auto;
}
.StripeElement {
    background-color: var(--primary-background-color);
    border-radius: 5px;
    padding: 13px 16px 3px 16px;
    margin: 0.5rem 0;
    height: calc(40px - 1rem);
    border: 1px solid #E2CFEA;
}

@media (max-width: 768px) {
    .StripeElement {
        padding: 9px 16px 6px 16px;
        height: 20px;
    }
}

#anonymous-donation-checkbox-field {
    padding: 0.5rem 0;
}

.payment-request-button-divisor {
    text-align: center;
    margin: 1rem auto;
}

.PaymentRequestButton {
    background-color: var(--primary-background-color);
    border: none;
    margin-top: 2rem;
}

.PaymentRequestButton > div {
    max-width: 20rem;
    top: -1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.radio__input {
    visibility: hidden;
    position: absolute;
}

#donation-quick-select {
    margin: 0 0 0.5rem 0;
}

#donation-quick-select > .radio__label {
    cursor: pointer;
}

.radio__span:nth-child(2) {
    margin-left: 0.5rem;
}

.radio__span:last-child {
    margin-left: 0.5rem;
}

#radio__label-donation-quick-select {
    display: inline-block;
    background-color: var(--primary-background-color);
    color: var(--secondary-color);
    border: 1px #E2CFEA solid;
    border-radius: 0.2rem;
    width: calc(50% - 0.65rem);
    padding: 1rem 0 1rem 0;
    cursor: pointer;
    margin-top: 0.5rem;
}

.donation-quick-select-selected {
    background-color: #E2CFEA !important;
    color: #102B3F !important;
    border: none !important;
    font-weight: bold;
}

.donation-quick-select-selected > label {
    font-weight: bold;
}

#donation-message {
    resize: none;
    font-family: inherit;
    width: 18rem;
    height: 4rem;
    padding: 0.5rem 1rem;
}

.completed-donation-img {
    width: 15rem;
    border-radius: 1rem;
}
#feedback-field textarea {
    font-family: inherit;
    width: 100%;
    height: 5rem;
    padding: 0.5rem 0.5rem;
    resize: none;
}

.rating-button {
    cursor: pointer;
    background: none;
    border: none;
}

.rating__input-star {
    margin: 0.5rem 0;
}
